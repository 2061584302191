<template>
  <div>
    <!-- Бүх хуудсанд ашиглагдах header section -->
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Бүтээгдэхүүний дэлгэрэнгүй</h2>
        </el-col>
        <el-col :span="12">
          <div class="actions text-right">
          </div>
        </el-col>
      </el-row>
    </header>
    <!-- Tabled content -->
    <div class="panel tabled">
      <el-tabs v-model="activeTabName">
        <el-tab-pane label="Бүгд" name="all">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="10">
                  <el-input v-model="search" placeholder="Бараа хайх" @input="onSearch" class="input-with-select">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="statusFilter" @change="onSearch" placeholder="Төлөв сонгох">
                    <el-option
                      v-for="item in statusFilterOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="selectedBrand" @change="onSearch" clearable filterable multiple placeholder="Брэнд хайх">
                    <el-option
                      v-for="item in brandOptions"
                      :key="item.value"
                      :label="item.brand_monName"
                      :value="item.brand_id">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="selectedWarehouse" @change="onSearch" clearable filterable placeholder="Агуулах сонгох">
                    <el-option
                      v-for="(warehouse, indexWarehouse) in warehouses"
                      :key="indexWarehouse"
                      :label="warehouse.name_mon"
                      :value="warehouse.warehouse_id">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown @command="handleSort" >
                    <el-button type="default">{{sortName}}<i class="el-icon-sort"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in filterData" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <!-- Product list table ued zaaval product-table gesen class uguh -->
            <el-table
              v-loading="isLoading"
              :data="tableData"
              size="mini"
              style="width: 100%">
              <el-table-column
                header-align="center"
                label="Бүтээгдэхүүн"
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="6">
                        <el-image
                          class="image-holder"
                          :src="Array.isArray(scope.row.variant_image) ? scope.row.variant_image[0] : scope.row.variant_image"
                          fit="contain"></el-image>
                    </el-col>
                    <el-col :span="18">
                        <strong>{{scope.row.product_name_eng}}</strong>
                      <br />
                        <span class="secondary">{{ mergeValues(scope.row.option) }}</span>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column
                label="SKU"
                prop="variant_sku">
              </el-table-column>
              <el-table-column
                prop="supplier_sku"
                label="Харилцагчийн SKU">
              </el-table-column>
              <el-table-column
                prop="brand_name_mon"
                label="Брэнд">
              </el-table-column>
              <el-table-column
                label="Хувилбарын төлөв">
                <template slot-scope="scope">
                  <el-tag size="mini" v-if="scope.row.variant_status === 'active'">Идэвхитэй</el-tag>
                  <el-tag size="mini" v-else-if="scope.row.variant_status === 'inactive'" type="info">Идэвхигүй</el-tag>
                  <!-- <el-tag size="mini" v-else-if="scope.row.variant_status === 'brand_inactive'" type="info">Брэнд хаалттай</el-tag> -->
                  <el-tag size="mini" v-else-if="scope.row.variant_status === 'price_inactive'" type="warning">Үнэ батлуулаагүй</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="variant_status"
                label="Төлөв өөрчлөх">
                <template slot-scope="scope">
                  <div>
                    <el-switch
                      v-model="scope.row.changeStatus"
                      @change="updateStockStatus(scope.row)"
                      active-color="#8fc273"
                      inactive-color="#ff929a"
                      active-value="active"
                      inactive-value='inactive'>
                    </el-switch>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Тоо ширхэг">
                  <template slot-scope="scope">
                    <el-input size="mini" @input="noDisable(scope.$index, scope.row.availableCount, 'count')" :disabled="generateDisabled(scope.row)" v-model="scope.row.availableCount"></el-input>
                  </template>
              </el-table-column>
              <el-table-column
                label="Борлуулах үнэ">
                <template slot-scope="scope">
                  <el-input size="mini" @input="noDisable(scope.$index, scope.row.variant_sellPrice, 'price')" :disabled="generateDisabled(scope.row)" v-model="scope.row.variant_sellPrice"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="Хямдралтай үнэ">
                <template slot-scope="scope">
                  <el-input size="mini" @input="noDisable(scope.$index, scope.row.variant_sale_price, 'sale_price')" :disabled="scope.row.isDisabled" v-model="scope.row.variant_sale_price">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column label="Хямдралтай эсэх" align="center">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.isDiscount" :disabled="generateDisabled(scope.row)" @change="isDiscount(scope.row)">{{scope.row.is_discount}}</el-checkbox>
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="Эхлэх өдөр">
                <template slot-scope="scope">
                  <el-input size="mini" @input="noDisable(scope.$index, scope.row.variant_sellPrice, 'price')" :disabled="generateDisabled(scope.row)" v-model="scope.row.discounted_price"></el-input>
                </template>
              </el-table-column> -->
              <!-- <el-table-column
                label="Дуусах өдөр">
                <template slot-scope="scope">
                  <el-input size="mini" @input="noDisable(scope.$index, scope.row.variant_sellPrice, 'price')" :disabled="generateDisabled(scope.row)" v-model="scope.row.discounted_price"></el-input>
                </template>
              </el-table-column> -->
              <el-table-column width="200px">
                <template slot-scope="scope">
                 <el-button
                    v-if="scope.row.edit === true"
                    size="mini"
                    type="success"
                    plain
                    @click="handleEdit(scope.$index, scope.row, 'edit')">
                    Засах
                  </el-button>
                 <el-button
                    :loading="isChangeLoading"
                    v-else
                    :disabled="buttonDisabled"
                    size="mini"
                    type="success"
                    @click="handleEdit(scope.$index, scope.row, 'change')">
                    Өөрчлөх
                  </el-button>
                  <el-button
                    v-if="scope.row.edit === false"
                    size="mini" type="danger"
                    @click="handleEdit(scope.$index, scope.row, 'cancel')">
                    Болих
                  </el-button>
                  <el-button
                    v-else
                    size="mini"
                    type="primary"
                    @click="handleInfo(scope.row.variant_id)">Дэлгэрэнгүй</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change = "curentPageHandler"
              @size-change = "sizeChangeHandler"
              class="text-right mt10"
              background
              :current-page.sync="currentPage"
              :page-size="pageSize"
              :page-sizes="[5, 20, 50, 100]"
              layout="total, sizes, prev, pager, next"
              :total="totalCount">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getUserName, getSupplierId } from '../../../utils/auth'
import services from '../../../helpers/services'
export default {
  name: 'stockList',
  components: {
    // MenuIcon
  },
  data () {
    return {
      selectedWarehouse: '',
      warehouses: [],
      tempData: 0,
      buttonDisabled: true,
      isChangeLoading: false,
      field: '',
      sort: '',
      sortName: 'Эрэмбэлэх',
      supplier_SKU: '',
      brandOptions: [],
      selectedBrand: [],
      currentSort: 'firstChar',
      totalCount: 0,
      pageSize: 20,
      pageFrom: 0,
      statusFilter: '',
      currentPage: this.$route.query.page ? JSON.parse(this.$route.query.page) : 1,
      search: '',
      quatityEditOption: 'Add',
      tableData: [],
      isLoading: false,
      activeTabName: 'all',
      statusFilterOptions: [{
        value: '',
        label: 'Бүгд'
      }, {
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }, {
        value: 'price_inactive',
        label: 'Үнэ батлуулаагүй'
      }],
      filterData: [
        {
          label: 'Бүгд',
          value: 'all'
        }, {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }, {
          label: 'Үлдэгдэл их',
          value: 'desc_count'
        }, {
          label: 'Үлдэгдэл бага',
          value: 'asc_count'
        }, {
          label: 'Агуулахад байхгүй ч зарах',
          value: 'sellCheck'
        }
      ]
    }
  },
  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingWarehouse = this.$route.query.warahouse
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingStatus = this.$route.query.status
      const incomingField = this.$route.query.field
      const incomingSort = this.$route.query.sort
      const incomingsortName = this.$route.query.sortName
      const incomingBrand = JSON.parse(this.$route.query.brand).length > 0 ? JSON.parse(this.$route.query.brand) : []
      this.selectedWarehouse = incomingWarehouse
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.statusFilter = incomingStatus
      this.field = incomingField
      this.sort = incomingSort
      this.sortName = incomingsortName
      this.selectedBrand = incomingBrand
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.pageFrom = from
    this.pageSize = size
    this.variantsBySupplierId(size, from)
    this.getBrand()
    this.getWarehousesBySupplierId()
  },
  methods: {
    async getWarehousesBySupplierId () {
      this.loading = true
      const body = {
        supplier_id: await getSupplierId(),
        size: 100,
        from: 0
      }
      services.getWarehousesBySupplierId(body).then(response => {
        this.loading = false
        if (response.status === 'success' && response.data) {
          this.warehouses = response.data
        } else {
          this.warehouses = []
        }
      })
    },
    isDiscount (data) {
      if (data.isDiscount === false) {
        data.isDisabled = true
      } else if (data.isDiscount === true) {
        data.isDisabled = false
      } else {
        data.isDisabled = true
      }
    },
    noDisable (index, data, type) {
      if (type === 'count' && parseInt(data) !== this.backupData[index].availableCount) {
        this.buttonDisabled = false
        this.tempData++
      } else if (type === 'price' && parseInt(data) !== this.backupData[index].variant_sellPrice) {
        this.buttonDisabled = false
        this.tempData++
      } else if (type === 'sale_price' && parseInt(data) !== this.backupData[index].variant_sellPrice) {
        this.buttonDisabled = false
        this.tempData++
      } else {
        this.buttonDisabled = true
      }
    },
    generateDisabled (data) {
      const tempData = data.edit
      return tempData
    },
    handleEdit (index, data, type) {
      if (type === 'edit') {
        data.edit = false
        if (this.tempData !== 0) {
          this.buttonDisabled = true
        }
      } else if (type === 'cancel') {
        data.availableCount = this.backupData[index].availableCount
        data.variant_sellPrice = this.backupData[index].variant_sellPrice
        data.edit = true
      } else if (type === 'change') {
        this.isChangeLoading = true
        if (data.availableCount !== this.backupData[index].availableCount) {
          const body = {
            variant: {
              variant_id: data.variant_id,
              count: data.availableCount
            }
          }
          services.updateVariant(body).then(response => {
            if (response.status === 'success') {
              this.isChangeLoading = false
              this.backupData[index].availableCount = data.availableCount
              this.alertReporter('Тоо ширхэг', response.message, 'success')
              data.edit = true
            } else {
              this.isChangeLoading = false
              data.availableCount = this.backupData[index].availableCount
              this.alertReporter('Уучлаарай', response.message, 'warning')
            }
          })
        }
        if (data.variant_sellPrice !== this.backupData[index].variant_sellPrice || data.variant_sale_price !== this.backupData[index].variant_sale_price) {
          const payload = {
            accepted_prices: []
          }
          const tempBody = {}
          tempBody.discounted_price = data.isDiscount === true ? parseInt(data.variant_sale_price) : 0
          tempBody.get_discounted_price = data.isDiscount === true ? (parseInt(data.variant_sale_price) - parseInt(data.variant_sale_price) * parseInt(data.brand_fee_percent) / 100).toFixed() : 0
          tempBody.is_discount = data.isDiscount
          tempBody.brand_id = data.brand_id ? data.brand_id : ''
          tempBody.supplier_id = data.supplier_id ? data.supplier_id : ''
          tempBody.get_price = (parseInt(data.variant_sellPrice) - parseInt(data.variant_sellPrice) * parseInt(data.brand_fee_percent) / 100).toFixed()
          tempBody.product_id = data.product_id ? data.product_id : ''
          tempBody.product_name = data.product_name_mon ? data.product_name_mon : ''
          tempBody.sell_price = parseInt(data.variant_sellPrice) ? parseInt(data.variant_sellPrice) : 0
          tempBody.variant_id = data.variant_id ? data.variant_id : ''
          tempBody.brand_fee = parseInt(data.brand_fee_percent) ? parseInt(data.brand_fee_percent) : 0
          tempBody.start_date = null
          tempBody.end_date = null
          payload.accepted_prices.push(tempBody)
          services.acceptedPrices(payload).then((response) => {
            if (response.status === 'success') {
              this.backupData[index].variant_sellPrice = data.variant_sellPrice
              data.edit = true
              this.isChangeLoading = false
              data.isDisabled = true
              this.alertReporter('Борлуулах үнэ', response.message, 'success')
            } else if (response.status === 'error') {
              this.isChangeLoading = false
              data.variant_sellPrice = this.backupData[index].variant_sellPrice
              this.alertReporter('Уучлаарай', response.message, 'warning')
            }
          })
        }
      } else {
        data.edit = true
        this.isChangeLoading = false
      }
    },
    getBrand () {
      const query = '?size=1000' + '&from=0'
      services.getBrand(query).then(response => {
        if (response.status === 'success' && response.data) {
          this.brandOptions = response.data
        }
      })
    },
    sizeChangeHandler (size) {
      this.pageSize = size
      this.$router.push({ name: 'inventory', query: { page: this.currentPage, size: this.pageSize, search: this.search, status: this.statusFilter, field: this.field, sort: this.sort, sortName: this.sortName, brand: JSON.stringify(this.selectedBrand), warahouse: this.selectedWarehouse } }).catch(() => {})
      this.variantsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    curentPageHandler (page) {
      this.currentPage = page
      this.$router.push({ name: 'inventory', query: { page: this.currentPage, size: this.pageSize, search: this.search, status: this.statusFilter, field: this.field, sort: this.sort, sortName: this.sortName, brand: JSON.stringify(this.selectedBrand), warahouse: this.selectedWarehouse } }).catch(() => {})
      this.variantsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    handleInfo (id) {
      this.$router.push(
        {
          name: 'stockDetail',
          params: {
            variant_id: id
          }
        }
      )
    },

    async updateStockStatus (data) {
      const body = {
        variant_status: data.changeStatus,
        variant_id: data.variant_id,
        user_mail: await getUserName()
      }
      this.isLoading = true
      services.updateStockStatus(body).then((response) => {
        if (response.status === 'success') {
          const tempData = JSON.parse(JSON.stringify(this.tableData))
          const isFound = tempData.findIndex(el => el.variant_id === data.variant_id)
          if (isFound > -1) tempData[isFound].variant_status = data.changeStatus
          this.alertReporter('Амжилттай', 'Төлөв амжилттай шинэчлэгдлээ', 'success')
          this.isLoading = false
          this.tableData = tempData
        } else {
          this.alertReporter('Алдаа гарлаа', response.message)
          this.isLoading = false
          data.changeStatus = data.variant_status
        }
      })
    },

    handleSort (data) {
      const filters = {
        newest: {
          field: 'created_at',
          sort: 'desc',
          sortName: 'Шинэ эхэнд'
        },
        oldest: {
          field: 'created_at',
          sort: 'asc',
          sortName: 'Хуучин эхэнд'
        },
        firstChar: {
          field: 'variant_name',
          sort: 'asc',
          sortName: 'Нэр А - Я'
        },
        lastChar: {
          field: 'variant_name',
          sort: 'desc',
          sortName: 'Нэр Я - А'
        },
        all: {
          field: '',
          sort: '',
          sortName: 'Эрэмбэлэх'
        },
        sellCheck: {
          field: 'sellCheck',
          sort: true,
          sortName: 'Агуулахад байхгүй ч зарах'
        },
        desc_count: {
          field: 'available_count',
          sort: 'desc',
          sortName: 'Үлдэгдэл их'
        },
        asc_count: {
          field: 'available_count',
          sort: 'asc',
          sortName: 'Үлдэгдэл бага'
        }
      }
      const currentFilter = JSON.parse(JSON.stringify(filters[data]))
      this.field = currentFilter.field
      this.sort = currentFilter.sort
      this.sortName = currentFilter.sortName
      this.variantsBySupplierId(this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    mergeValues (data) {
      var tempData = ''
      data.forEach((element, index) => {
        tempData = tempData + (index === 0 ? '' : ' / ') + element.value
      })
      return tempData
    },

    variantsBySupplierId (size, from) {
      this.isLoading = true
      const body = {
        search_text: this.search,
        field: this.field,
        sort: this.sort,
        status: this.statusFilter,
        size: size,
        brand_id: this.selectedBrand,
        warehouse_id: this.selectedWarehouse,
        from: from
      }
      services.variantsBySupplierId(body).then(response => {
        if (response.status === 'success' && response.data) {
          this.isLoading = false
          this.backupData = response.data
          const tempData = JSON.parse(JSON.stringify(response.data))
          this.totalCount = response.total
          tempData.forEach(product => {
            product.edit = true
            product.changeStatus = product.variant_status
            product.isDiscount = false
            product.isDisabled = true
          })
          this.tableData = tempData
        } else if (response.status === 'error') {
          this.tableData = []
          this.isLoading = false
        } else {
          this.isLoading = false
        }
      })
    },

    onSearch () {
      this.currentPage = 1
      this.$router.push({ name: 'inventory', query: { page: this.currentPage, size: this.pageSize, search: this.search, status: this.statusFilter, field: this.field, sort: this.sort, sortName: this.sortName, brand: JSON.stringify(this.selectedBrand), warehouse: this.selectedWarehouse } }).catch(() => {})
      this.variantsBySupplierId(this.pageSize, this.pageFrom)
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    }
  }
}
</script>
